import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import Testimonials from '../components/Testimonials'
import Pricing from '../components/Pricing'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Videography from '../components/Videography'
import Graphics from '../components/Graphics'
import SocialMedia from '../components/SocialMedia'
import Webdev from '../components/Webdev'
import Slide from 'react-reveal/Slide';

export const ServicePageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  testimonials,
  fullImage,
  pricing,
}) => (
  <div className="content">
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    >
      <h2
        className="has-text-weight-bold is-size-1"
        style={{
          boxShadow: '0.5rem 0 0 #36454f, -0.5rem 0 0 #36454f',
          backgroundColor: '#3d4c51a8',
          color: 'white',
          padding: '1rem',
        }}
      >
        {title}
      </h2>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-7 is-offset-1">
              <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
              <p>{description}</p>
            </div>
          </div>
          <div className="container">
        <div className="section">
                <Slide bottom cascade>
                <div className="columns is-multiline">
             
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Photography & Videography</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '15%', width: '150px', border: 'none'}} />
                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                            
                          }}
                        >
                          
                         <Videography />
                        </div>
                      </div>
                      <p>If you are thinking about the most efficient and effective way of communication, then you need to think about video content.
From business promos to reviewing products, we can cater to your business’ needs. Our video content team will produce ‘scroll stopping’ videos for you.</p>
                    </section>
                  </div>
              
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Website Design & Development</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '12%', width: '150px', border: 'none'}} />

                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                          }}
                          
                        >
                         <Webdev />
                        </div>
                      </div>
                      <p>Our team has experts in designing and developing websites.
We design the UI of websites so that your clients will have a remarkable journey. BrizzyMedia also offers Search Engine Optimization for clients. You don’t want your hard work to get lost amidst hundreds of pages in Google.</p>
                    </section>
                  </div>
              </div>
              </Slide>
              <Slide bottom cascade>
                <div className="columns is-multiline">
             
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Graphic Design & Branding</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '18%', width: '150px', border: 'none'}} />
                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                            
                          }}
                        >
                          
                         <Graphics />
                        </div>
                      </div>
                      <p>Our Graphic Design & Branding  team is made up of experts who will add value to your digital footprint.
We truly offer a “Limitless Online Presence”.</p>
                    </section>
                  </div>
              
                  <div className="column is-6">
                    <section className="section">
                      <div className="has-text-centered">
                      <h1>Social Media & Digital Marketing</h1>
                        <hr style={{backgroundColor: '#3d4c51', height: '5px', marginLeft: '10%', width: '150px', border: 'none'}} />

                        <div
                          className="services"
                          style={{
                            width: '240px',
                            display: 'inline-block',
                          }}
                          
                        >
                         <SocialMedia />
                        </div>
                      </div>
                      <p>Online Marketing has been a successful medium for increasing sales and revenues.

Over the last decade, the world of marketing has pivoted towards online services. It is now more important to be available on Amazon, eBay, Social Media and Google. You need to provide 24/7 access to your customers.</p>
                    </section>
                  </div>
              </div>
              </Slide>
               

             
                            
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

ServicePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

const ServicePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ServicePageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  )
}

ServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicePage

export const servicePageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        main {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        testimonials {
          author
          quote
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`
